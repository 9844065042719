import PropTypes from "prop-types";
import { useMemo } from "react";
import { getAppUrl } from "../../api-services/axiosClient";
import CustomButton from "../../components/custom-button";
import { DefaultAvatar, DefaultCover } from "../../images";
import "../../scss/broker.scss";

const convertStaffRoleEnumToString = (staffRoleEnum) => {
  switch (staffRoleEnum) {
    case "WEALTH_PLANNER":
      return "Wealth Planner";
    case "BROKER":
      return "Broker";
    case "REAL_ESTATE_AGENT":
      return "Real Estate Agent";
    case "COMPANY_ADMIN":
      return "Company Admin";
    default:
      return "Undefined";
  }
};

const BrokerDetails = ({
  brokerDetails = {},
  handleModal = () => {},
  handleBrokerDetails = () => {},
}) => {
  const {
    staffCode,
    bottomImageUrl,
    imageUrl,
    staffRoles,
    user: { firstName, lastName },
  } = brokerDetails;

  const staffRole = staffRoles.reduce((acc, curr, index, arr) => {
    if (index === arr.length - 1) {
      return `${acc + convertStaffRoleEnumToString(curr.role)}`;
    }
    return `${acc + convertStaffRoleEnumToString(curr.role)}, `;
  }, "");
  const brokerLandingPageUrl = useMemo(() => {
    return `${getAppUrl()}/staff/${staffCode}`;
  }, [staffCode]);

  const handleUpdateBroker = () => {
    handleBrokerDetails(brokerDetails);
    handleModal(true);
  };

  return (
    <div className="broker-details">
      <div className="broker-details-image">
        <div className="broker-details-image__feature-image">
          <img src={bottomImageUrl || DefaultCover} alt="broker-feature" />
        </div>
        <div className="broker-details-image__broker-image">
          <img src={imageUrl || DefaultAvatar} alt="broker avatar" />
        </div>
      </div>
      <div className="broker-information">
        <div className="broker-information__name">{`${firstName} ${lastName}`}</div>
        <div className="broker-information__role">{staffRole}</div>
        <a href={brokerLandingPageUrl}>{brokerLandingPageUrl}</a>
      </div>
      <CustomButton label="View profile" onClick={handleUpdateBroker} />
    </div>
  );
};

BrokerDetails.propTypes = {
  brokerDetails: PropTypes.object,
};

export default BrokerDetails;
